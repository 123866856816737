/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Collapse, Dialog, DialogActions, DialogContent,
  IconButton, MenuItem,
  Paper, Table, TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow, TableSortLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addDays, startOfDay } from 'date-fns';
import KnPageContent from '../../components/Content';
import KnPatientRecordBox from './styles';
import { KnBold, KnBoldSectionHeader, KnSmall } from '../../components/Typography';
import { translate } from '../../i18n/i18n';
import { withKeyNamespace } from '../../utils/utils';
import palette from '../../styles/colors';
import KnArrowDownIcon from '../../components/icons/ArrowDownIcon';
import KnDatePicker from '../../components/DatePicker';
import KnButton from '../../components/Button';
import { KnContrastTextField } from '../../components/TextField';
import surveyActions from '../../redux/actions/surveyActions';
import patientActions from '../../redux/actions/patientActions';
import { PATIENT_SURVEY_FREQUENCY } from '../../Constants';

const MIN_DATE = addDays(new Date(), 1);
const ONE_TIME_FREQUENCY = 'one_time';
/**
 * Page used for assigning a regimen
 */
const i18nKey = withKeyNamespace('ASSIGN_SURVEY');

const useRowStyles = makeStyles({
  root: {
    verticalAlign: 'top',
  },
});

const StyledCheckbox = styled(Checkbox)({
  transform: 'scale(1.3)',
  color: palette.coolGrey.coolGrey1,
});

const ActiveSurveySpan = styled('span')({
  marginLeft: 30,
  color: palette.paleGrey.paleGrey4,
});

const ActiveSurveyDescription = styled('span')(({ checked }) => ({
  color: checked ? palette.error.main : palette.paleGrey.paleGrey4,
}));

const SurveyDetails = styled('a')({
  textDecoration: 'none',
  color: palette.primary,
  marginLeft: 10,
  cursor: 'pointer',
});

function Row({ row, handleFieldChange }) {
  const classes = useRowStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [frequency, setFrequency] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);

  const onStartDateChange = (newDate) => {
    setStartDate(newDate);
    handleFieldChange('startDate', newDate);
  };

  const onEndDateChange = (newDate) => {
    setEndDate(newDate);
    handleFieldChange('endDate', newDate);
  };

  const onCheckboxChange = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    handleFieldChange('checked', checked);
    if (checked) setOpen(true);
  };

  const onFrequencyChange = ({ target: { value } }) => {
    setFrequency(value);
    handleFieldChange('frequency', value);
  };

  const minEndDate = addDays(startDate || MIN_DATE, 1);

  return (
    <TableRow className={classes.root}>
      <TableCell width={50}>
        <StyledCheckbox color="primary" onChange={onCheckboxChange} />
      </TableCell>
      <TableCell width={120}>
        <Typography component={KnBold}>{row.surveyId}</Typography>
      </TableCell>
      <TableCell>
        <Typography component={KnBold}>
          {row.title}
          {row.assignedSurvey && (
            <ActiveSurveySpan>
              (
              <ActiveSurveyDescription checked={isChecked}>{translate(i18nKey('activeSurvey'))}</ActiveSurveyDescription>
              {translate(i18nKey('activeSurveyDescription'))}
              )
            </ActiveSurveySpan>
          )}
        </Typography>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box mt={2}>
            <Typography component={KnSmall}>
              {row.surveyDescription}
              <SurveyDetails onClick={() => {
                dispatch(patientActions.callPdfFunction(row.id));
              }}
              >
                {translate(i18nKey('surveyDetails'))}
              </SurveyDetails>
            </Typography>
          </Box>
          <Box pt={5} pb={2} style={{ display: 'flex' }}>
            <Box style={{ width: 300 }}>
              <KnDatePicker
                label={translate(i18nKey('startDate'))}
                value={startDate}
                onChange={onStartDateChange}
                minDate={MIN_DATE}
                required={isChecked}
                disabled={!isChecked}
              />
              {frequency && frequency !== ONE_TIME_FREQUENCY && (
              <KnDatePicker
                label={translate(i18nKey('endDate'))}
                value={endDate}
                onChange={onEndDateChange}
                minDate={minEndDate}
                required={isChecked}
                disabled={!isChecked}
              />
              )}
            </Box>
            <Box style={{ width: 300, marginLeft: 20 }}>
              <KnContrastTextField
                select
                value={frequency}
                label={translate(i18nKey('frequencyLabel'))}
                onChange={onFrequencyChange}
                required={isChecked}
                disabled={!isChecked}
                pb={0}
              >
                {Object.entries(PATIENT_SURVEY_FREQUENCY).map(([key, value]) => (
                  <MenuItem key={key} value={key}>{value}</MenuItem>
                ))}
              </KnContrastTextField>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
      <TableCell align="right">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          <KnArrowDownIcon color={palette.bostonBlue} style={{ transform: open ? 'rotate(180deg)' : 'none' }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number.isRequired,
    surveyId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    surveyDescription: PropTypes.string.isRequired,
    surveyPdfUrl: PropTypes.string,
    assignedSurvey: PropTypes.object,
  }).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
};

const CollapsibleTable = ({
  rows, handleFieldChange, handleSortBy, sortBy, sortDirection,
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
      <TableHead>
        <TableRow style={{ backgroundColor: palette.white.white1 }}>
          <TableCell />
          <TableCell>
            <TableSortLabel onClick={() => handleSortBy('surveyId')} direction={sortDirection} active={sortBy === 'surveyId'}>Survey ID</TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel onClick={() => handleSortBy('title')} direction={sortDirection} active={sortBy === 'title'}>Survey Name</TableSortLabel>
          </TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <Row
            key={row.id}
            row={row}
            handleFieldChange={(field, value) => handleFieldChange(field, value, row.id)}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

CollapsibleTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleSortBy: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
};

const AddSurveyActionButtonBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  '& button': {
    width: 360,
  },
});

const StyledDialog = withStyles({
  paper: {
    backgroundColor: palette.primary,
    boxShadow: 'none',
    borderRadius: 0,
    '& .MuiDialogContent-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      color: 'white',
      height: 300,
    },
    '& .MuiDialogActions-root': {
      paddingTop: 0,
      paddingBottom: 45,
      display: 'flex',
      justifyContent: 'space-around',
      '& button': {
        backgroundColor: palette.white.white2,
        color: palette.primary,
        fontWeight: 500,
        width: 300,
        textTransform: 'uppercase',
      },
    },
  },
})(Dialog);

const ConfirmationDialog = ({ handleClose, handleConfirm }) => (
  <StyledDialog onClose={handleClose} open fullWidth maxWidth="md">
    <DialogContent>
      <Typography variant="h5" component={KnBoldSectionHeader}>
        {translate(i18nKey('confirmationTitle'))}
      </Typography>
    </DialogContent>
    <DialogActions>
      <KnButton onClick={handleClose}>{translate(i18nKey('returnButton'))}</KnButton>
      <KnButton onClick={handleConfirm}>{translate(i18nKey('confirmButton'))}</KnButton>
    </DialogActions>
  </StyledDialog>
);

ConfirmationDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

const AssignSurveyPage = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: surveysList, sortBy, sortDirection,
  } = useSelector((state) => state.surveysList);

  const patientId = Number(match.params.patientId);
  useEffect(() => {
    dispatch(surveyActions.fetchSurveysList(patientId));
    dispatch(patientActions.fetchPatientInfo(patientId));
  }, [dispatch, patientId]);

  const { data: patientInfo } = useSelector((state) => state.patientRecord.patientInfo);
  const patientName = patientInfo ? `${patientInfo.firstName} ${patientInfo.lastName}` : null;


  const [isAssignButtonEnabled, setIsAssignButtonEnabled] = useState(false);
  const handleFieldChange = (field, value, id) => {
    surveysList.find((survey) => survey.id === id)[field] = value;

    const checkedSurveyCount = surveysList.filter((survey) => survey.checked).length;
    const validSurveyCount = surveysList.filter((survey) => survey.checked
        && survey.startDate && survey.frequency
        && (survey.frequency === ONE_TIME_FREQUENCY
            || startOfDay(survey.endDate) > startOfDay(survey.startDate))).length;

    setIsAssignButtonEnabled(checkedSurveyCount && checkedSurveyCount === validSurveyCount);
  };

  const handleReturnToPatient = () => {
    history.goBack();
  };

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const toggleConfirmationDialog = () => setIsConfirmationDialogOpen(!isConfirmationDialogOpen);

  const handleConfirm = () => {
    const data = surveysList.filter((survey) => survey.checked).map((survey) => ({
      survey: { id: survey.id },
      startDate: survey.startDate,
      endDate: survey.endDate,
      frequency: survey.frequency,
      status: 'assigned',
    }));

    dispatch(surveyActions.assignSurvey(patientId, data)).then(() => handleReturnToPatient());
  };

  const handleSortBy = (sortKey) => dispatch(surveyActions.sortSurveysList(sortKey));

  return (
    <KnPageContent>
      <KnPatientRecordBox mb={2} extrapadding="true">
        <Typography variant="h6" component={KnBoldSectionHeader}>{translate(i18nKey('assignSurveyTitle'), { name: patientName })}</Typography>
        <Typography component={KnSmall}>{translate(i18nKey('assignSurveySubtitle'))}</Typography>
      </KnPatientRecordBox>

      {surveysList.length > 0 && (
      <>
        <CollapsibleTable
          rows={surveysList}
          handleFieldChange={handleFieldChange}
          handleSortBy={handleSortBy}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />

        <AddSurveyActionButtonBox mt={4}>
          <KnButton onClick={handleReturnToPatient}>{translate(i18nKey('returnToPatient'))}</KnButton>
          <KnButton disabled={!isAssignButtonEnabled} onClick={toggleConfirmationDialog}>{translate(i18nKey('assignSurvey'))}</KnButton>
        </AddSurveyActionButtonBox>
      </>
      )}

      {isConfirmationDialogOpen && (
      <ConfirmationDialog
        handleClose={toggleConfirmationDialog}
        handleConfirm={handleConfirm}
      />
      )}
    </KnPageContent>
  );
};

AssignSurveyPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AssignSurveyPage;
