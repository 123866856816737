/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import { translate } from 'i18n/i18n';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {
  APP_PAGE_URLS,
  PATIENTS_LIST_TABS,
  PATIENT_LINKS_STATUS,
  TRACKING_EVENTS,
  HCP_USER_ROLES,
  PATIENTS_LIST_TYPES,
  REGIMEN_STATUS_LIST,
  REGIMEN_STATUS_COLORS,
} from 'Constants';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader, KnColoredText } from 'components/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import useSessionStorage, { SESSION_STORAGE_KEYS } from 'utils/sessionStorage';
import KnTable from 'components/Table';
import patientActions from 'redux/actions/patientActions';
import { filterByPhysician, filterPatientsByName, filterPatientsByRegimenStatus } from 'utils/patientsFilters';
import ACTION_TYPES from 'redux/actionTypes';
import palette from 'styles/colors';
import { withStyles } from '@material-ui/core/styles';
import { subDays } from 'date-fns';
import KnPatientsTableToolbar from './PatientsTableToolbar';
import InvitePatientToolbar from './InvitePatientToolbar';

const redColor = palette.copper;

const mapTabToPatientLinkStatus = (tab) => {
  switch (tab) {
    case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
      return PATIENT_LINKS_STATUS.unlinked;
    case PATIENTS_LIST_TABS.INVITED_PATIENTS:
      return PATIENT_LINKS_STATUS.invited;
    default:
      return PATIENT_LINKS_STATUS.accepted;
  }
};

const mapTabToPatientType = (tab) => {
  switch (tab) {
    case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
      return PATIENTS_LIST_TYPES.UNLINKED;
    case PATIENTS_LIST_TABS.INVITED_PATIENTS:
      return PATIENTS_LIST_TYPES.INVITED;
    default:
      return PATIENTS_LIST_TYPES.VERIFIED;
  }
};

const StyledTabs = withStyles({
  root: {
    marginBottom: 2,
  },
})(Tabs);

const LinkButton = withStyles({
  root: {
    marginLeft: 'auto',
    marginRight: 30,
    fontSize: 16,
    fontWeight: 500,
  },
})(Button);

const getTableColumns = (columns) => {
  const regimenStatusDisplay = (latestRegimenStatus) => (
    <KnColoredText color={REGIMEN_STATUS_COLORS[latestRegimenStatus]}>
      {REGIMEN_STATUS_LIST[latestRegimenStatus]}
    </KnColoredText>
  );

  const thresholdMetDisplay = (rowData) => {
    if (rowData.thresholdMet) {
      return (
        <KnColoredText color={redColor}>
          {translate('GENERAL.date', { date: rowData.thresholdMet })}
        </KnColoredText>
      );
    }
    return '-';
  };

  const dateInvitedDisplay = (rowData) => {
    const dateInvitedFormatted = translate('GENERAL.date', { date: rowData.dateInvited });
    return subDays(new Date(), 3) > rowData.dateInvited
      ? <KnColoredText color={redColor}>{dateInvitedFormatted}</KnColoredText>
      : dateInvitedFormatted;
  };

  const tableColumns = [
    {
      name: translate('HOME.tableHeaders.lastName'),
      valueAccessor: 'lastName',
      sortKey: 'lastName',
    },
    {
      name: translate('HOME.tableHeaders.firstName'),
      valueAccessor: 'firstName',
      sortKey: 'firstName',
    },
    {
      name: translate('GENERAL.dob'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dob }),
      sortKey: 'dob',
    },
    {
      name: translate('HOME.tableHeaders.accountNumber'),
      valueAccessor: 'accountNumber',
      sortKey: 'accountNumber',
    },
    {
      name: translate('HOME.tableHeaders.emailAddress'),
      valueAccessor: 'email',
      sortKey: 'email',
    },
    {
      name: translate('HOME.tableHeaders.physician'),
      valueAccessor: 'physician',
      sortKey: 'physician',
    },
    {
      name: translate('GENERAL.gender.label'),
      valueAccessor: (rowData) => rowData.gender && translate(`GENERAL.gender.${rowData.gender}`),
      sortKey: 'gender',
    },
    {
      name: translate('HOME.tableHeaders.threshold'),
      valueAccessor: thresholdMetDisplay,
      sortKey: 'thresholdMet',
    },
    {
      name: translate('HOME.tableHeaders.dateOfDeactivation'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dateOfDeactivation }),
      sortKey: 'dateOfDeactivation',
    },
    {
      name: translate('HOME.tableHeaders.dateInvited'),
      valueAccessor: dateInvitedDisplay,
      sortKey: 'dateInvited',
    },
    {
      name: translate('HOME.tableHeaders.regimenStatus'),
      valueAccessor: (rowData) => regimenStatusDisplay(rowData.latestRegimenStatus),
      sortKey: 'latestRegimenStatus',
    },
  ];

  return tableColumns.filter((col) => columns.includes(col.sortKey));
};

const KnPatientLists = ({ listType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    error,
    invited,
    verified,
    unlinked,
    sortBy,
    sortDirection,
  } = useSelector((state) => state.patientsList);
  const {
    currentUser,
    physiciansList,
  } = useSelector((state) => state.user);

  const [selectedTab, setSelectedTab] = useState(PATIENTS_LIST_TABS[listType]);
  const [showInviteToolbar, setShowInviteToolbar] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  // eslint-disable-next-line max-len
  const [regimenStatusFilter, setRegimenStatusFilter] = useState([]);
  const [physicianFilter, setPhysicianFilter] = useSessionStorage(
    SESSION_STORAGE_KEYS.physicianFilter,
  );

  const fetchPatientsList = useCallback((tab, silent = true) => {
    dispatch(patientActions.fetchPatients(
      mapTabToPatientLinkStatus(tab),
      silent,
    ));
  }, [dispatch]);

  useEffect(() => {
    /** Set selected tab on listType change in URL */
    setSelectedTab(PATIENTS_LIST_TABS[listType]);
    /** Fetch the appropriate patient links. */
    fetchPatientsList(PATIENTS_LIST_TABS[listType]);
  }, [fetchPatientsList, listType]);

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientList });
  }, []);

  useEffect(() => {
    const tableInitialized = invited || verified || unlinked;
    if (physiciansList.initialized && !tableInitialized) {
      /** Ready to set default (initial) filter for the patients list
       * and make the first data fetch
       */
      let filter = physicianFilter;
      if (physiciansList.data.length === 0) {
        /** If filter options are missing (eg. failed to get physicians),
         * set/reset filter to 'all'
         */
        filter = 0;
      } else if (!physicianFilter) {
        /** If there's no physician filter set yet,
         * initialize it with current physician OR 'all', depending on user role
         */
        filter = (_.get(currentUser, 'role.id') === HCP_USER_ROLES.physician
          && physiciansList.data.length > 0) ? currentUser.userId : 0;
      }

      /** If filter got set/updated, save it in session storage, for future use */
      if (filter !== physicianFilter) {
        setPhysicianFilter(filter);
      }
      dispatch(patientActions.fetchPatients(
        mapTabToPatientLinkStatus(selectedTab),
        false,
      ));
    }
  }, [dispatch, currentUser, physiciansList, invited,
    verified, unlinked, selectedTab, setPhysicianFilter, physicianFilter,
  ]);

  const onTableRowClick = useCallback((row) => {
    if (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) {
      /** First clear previous patient data, to avoid old data flash */
      dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_RECORD });
      /** Set available new patient data, rest will be fetched by the PatientRecord page */
      dispatch(patientActions.setPatientInfo(row));
      history.push(
        APP_PAGE_URLS.patientRecord.replace(':patientId', row.patientId),
        { patientType: false },
      );
    }
    if (selectedTab === PATIENTS_LIST_TABS.INVITED_PATIENTS) {
      dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_RECORD });
      dispatch(patientActions.setPatientInfo(row));
      history.push(
        APP_PAGE_URLS.invitedPatientRecord.replace(':patientId', row.patientId),
        { patientType: true },
      );
    }
  }, [dispatch, history, selectedTab]);

  const verifiedPatientsTableColumns = useMemo(() => getTableColumns(['lastName', 'firstName', 'dob', 'accountNumber', 'email', 'physician', 'thresholdMet', 'latestRegimenStatus']), []);
  const unlinkedPatientsTableColumns = useMemo(() => getTableColumns(['lastName', 'firstName', 'dob', 'accountNumber', 'email', 'gender', 'dateOfDeactivation']), []);
  const invitedPatientsTableColumns = useMemo(() => getTableColumns(['lastName', 'firstName', 'dob', 'email', 'physician', 'dateInvited', 'latestRegimenStatus']), []);

  const onTabChange = useCallback((...args) => {
    /** First parameter is event; we only need the second parameter (tabIndex). */
    const [, tabIndex] = args;
    const type = mapTabToPatientType(tabIndex.toString());

    /** Clear the search input. */
    setSearchTerm('');
    setShowInviteToolbar(false);
    setRegimenStatusFilter([]);

    // Redirect to patient list type tab
    history.push(
      APP_PAGE_URLS.patientList.replace(':listType', type),
    );
  }, [history]);

  const onSearchTermChange = useCallback(({ target: { value } }) => {
    setSearchTerm(value);
  }, []);

  const onPhysicianFilterChange = useCallback((event) => {
    /** `value` for 'All' option is undefined, exactly what we want  */
    const { target: { value } } = event;
    /** Save in session storage, to be able to keep on page refresh */
    setPhysicianFilter(value);
  }, [setPhysicianFilter]);

  const onRegimenStatusFilterChange = ({ target: { value } }) => {
    setRegimenStatusFilter(value);
  };

  const hasSearchTerm = searchTerm.trim();
  const hasPhysicianFilter = physicianFilter > 0;
  const hasRegimenStatusFilter = regimenStatusFilter.length > 0
    && regimenStatusFilter.length < Object.keys(REGIMEN_STATUS_LIST).length;

  const isVerifiedPatientsTab = (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS);
  const isUnlinkedPatientsTab = (selectedTab === PATIENTS_LIST_TABS.UNLINKED_PATIENTS);
  const isInvitedPatientsTab = (selectedTab === PATIENTS_LIST_TABS.INVITED_PATIENTS);

  const tableData = useMemo(() => {
    /** If there is a searching value, then we prioritize the filtered collection. */
    let dataSource = [];
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.VERIFIED_PATIENTS:
        dataSource = verified;
        break;
      case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
        dataSource = unlinked;
        break;
      case PATIENTS_LIST_TABS.INVITED_PATIENTS:
        dataSource = invited;
        break;
      default:
        dataSource = [];
    }

    if (hasSearchTerm) {
      dataSource = filterPatientsByName(dataSource, searchTerm, translate, selectedTab);
    }
    if (hasRegimenStatusFilter && !isUnlinkedPatientsTab) {
      dataSource = filterPatientsByRegimenStatus(dataSource, regimenStatusFilter);
    }
    if (hasPhysicianFilter && !isUnlinkedPatientsTab) {
      dataSource = filterByPhysician(dataSource, Number(physicianFilter));
    }

    return dataSource;
  }, [selectedTab, hasSearchTerm, hasRegimenStatusFilter,
    hasPhysicianFilter, verified, unlinked, invited, searchTerm,
    regimenStatusFilter, physicianFilter, isUnlinkedPatientsTab]);

  const tableColumns = useMemo(() => {
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.VERIFIED_PATIENTS:
        return verifiedPatientsTableColumns;
      case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
        return unlinkedPatientsTableColumns;
      case PATIENTS_LIST_TABS.INVITED_PATIENTS:
        return invitedPatientsTableColumns;
      default:
        return verifiedPatientsTableColumns;
    }
  }, [
    selectedTab,
    verifiedPatientsTableColumns,
    unlinkedPatientsTableColumns,
    invitedPatientsTableColumns,
  ]);

  const tableHintMessage = useMemo(() => {
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.VERIFIED_PATIENTS:
        return translate('HOME.patientTables.verifiedHint');
      case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
        return translate('HOME.patientTables.unlinkedHint');
      case PATIENTS_LIST_TABS.INVITED_PATIENTS:
        return translate('HOME.patientTables.invitedHint');
      default:
        return translate('HOME.patientTables.verifiedHint');
    }
  }, [selectedTab]);

  const tableEmptyDataMessage = useMemo(() => {
    if (!error && hasSearchTerm && tableData && !tableData.length) {
      return translate('HOME.patientTables.emptySearchResults');
    }
    if (!error && tableData && !tableData.length) {
      return translate('HOME.patientTables.emptyFilteredList');
    }
    if (!error && isVerifiedPatientsTab && verified && !verified.length) {
      return translate('HOME.patientTables.emptyVerifiedList');
    }
    if (!error && invited && !invited.length) {
      return translate('HOME.patientTables.emptyInvitedList');
    }
    if (!error && isUnlinkedPatientsTab && unlinked && !unlinked.length) {
      return translate('HOME.patientTables.emptyUnlinkedList');
    }
    return '';
  }, [
    error, isVerifiedPatientsTab, verified, invited,
    hasSearchTerm, tableData,
    isUnlinkedPatientsTab, unlinked,
  ]);

  const redoFetchPatientsList = useCallback(() => {
    /** On error refetch the patients list and show the progress indicator. */
    fetchPatientsList(selectedTab, false);
  }, [fetchPatientsList, selectedTab]);

  const TableDataErrorComponent = useMemo(() => (
    <KnErrorMessage
      error={error}
      messageKey="HOME.ERROR_MESSAGES.patientsListError"
      onRetry={redoFetchPatientsList}
    />
  ), [error, redoFetchPatientsList]);

  const onRequestSort = useCallback((sortKey) => {
    dispatch(patientActions.sortPatients(sortKey, mapTabToPatientType(selectedTab)));
  }, [dispatch, selectedTab]);

  const handleInvitePatientClick = () => {
    setSearchTerm('');
    setShowInviteToolbar(true);
    history.push('/patients/invited');
  };

  const onInvitePatientSuccess = () => {
    setSearchTerm('');
    setShowInviteToolbar(false);
    setRegimenStatusFilter([]);
    setPhysicianFilter(0);
  };

  return (

    <>
      <Typography variant="h4" component={KnBoldSectionHeader}>
        {translate('HOME.mainTitle')}
      </Typography>

      <Box pt={1} height={50}>
        <Typography variant="body2">{tableHintMessage}</Typography>
      </Box>

      <StyledTabs value={parseInt(selectedTab, 10)} onChange={onTabChange} textColor="primary" indicatorColor="primary">
        <Tab label={translate('HOME.tabs.tabActive')} data-testid="active-patients-tab" />
        <Tab label={translate('HOME.tabs.tabUnlinked')} data-testid="unlinked-patients-tab" />
        <Tab label={translate('HOME.tabs.tabInvited')} data-testid="invited-patients-tab" />
        {(process.env.REACT_APP_ALLOW_INVITE === 'true' && !showInviteToolbar) && (<LinkButton color="primary" onClick={handleInvitePatientClick} data-testid="invite-patient-button">{translate('HOME.invitePatient')}</LinkButton>)}
      </StyledTabs>

      {isInvitedPatientsTab && showInviteToolbar
        && <InvitePatientToolbar onInvitePatientSuccess={onInvitePatientSuccess} />}

      <KnPatientsTableToolbar
        searchTerm={searchTerm}
        onSearchTermChange={onSearchTermChange}
        physicianFilter={physiciansList.data.length ? Number(physicianFilter) : undefined}
        onPhysicianFilterChange={onPhysicianFilterChange}
        disabled={isUnlinkedPatientsTab}
        regimenStatusFilter={regimenStatusFilter}
        onRegimenStatusFilterChange={onRegimenStatusFilterChange}
      />

      <KnTable
        rowKeyAccessor="linkId"
        columns={tableColumns}
        data={tableData}
        emptyDataMessage={tableEmptyDataMessage}
        DataErrorComponent={TableDataErrorComponent}
        onRequestSort={onRequestSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onRowClick={onTableRowClick}
      // onRowClick={isVerifiedPatientsTab ? onTableRowClick : null}
      />
    </>
  );
};

KnPatientLists.propTypes = {
  listType: PropTypes.string.isRequired,
};

export default KnPatientLists;
