/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { PATIENTS_LIST_TABS, REGIMEN_STATUS_LIST } from '../Constants';

/**
 * Utility function to filter a collection of patients given a search term;
 * the search is case-insensitive and will be applied on fullname.
 *
 * @param {array} data The collection of patients to be filtered by name.
 * @param {string} term The search term to be used to filter the patients.
 * @param {object} translate To convert date to correct Date format.
 */
export const filterPatientsByName = (data, term, translate, selectedTab) => {
  /** Let's make case-insensitive search. */
  const filterBy = term.trim().toLowerCase();

  /**
   * Search on either firstname or lastname; convert to lower case
   * to match case of the term.
   */
  return (
    _.filter(data, (item) => {
      const dobSearch = translate('GENERAL.date', { date: item.dob });
      const commonSearchFields = `${item.lastName.toLowerCase()} ${item.firstName.toLowerCase()} ${item.email.toLowerCase()} ${dobSearch}`;
      let customSearchFields;
      const {
        thresholdMet, physician, latestRegimenStatus,
      } = item;
      const gender = (item.gender) ? item.gender : 'undisclosed';

      if (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) {
        const regimentStatus = REGIMEN_STATUS_LIST[latestRegimenStatus];
        const dateThresholdMet = (thresholdMet) && translate('GENERAL.date', { date: thresholdMet });
        customSearchFields = `${dateThresholdMet} ${item.accountNumber.toLowerCase()} ${(physician || '').toLowerCase()} ${(regimentStatus || '').toLowerCase()}`;
      } else if (selectedTab === PATIENTS_LIST_TABS.UNLINKED_PATIENTS) {
        const dateOfDeactivation = translate('GENERAL.date', { date: item.dateOfDeactivation });
        customSearchFields = `${dateOfDeactivation} ${item.accountNumber.toLowerCase()} ${gender}`;
      } else if (selectedTab === PATIENTS_LIST_TABS.INVITED_PATIENTS) {
        const regimentStatus = REGIMEN_STATUS_LIST[latestRegimenStatus];
        const dateInvited = translate('GENERAL.date', { date: item.dateInvited });
        customSearchFields = `${dateInvited} ${(physician || '').toLowerCase()} ${(regimentStatus || '').toLowerCase()}`;
      }

      return commonSearchFields.indexOf(filterBy) > -1 || customSearchFields.indexOf(filterBy) > -1;
    })
  );
};

// eslint-disable-next-line max-len
export const filterPatientsByRegimenStatus = (data, regimenStatusFilter) => _.filter(data, (item) => (regimenStatusFilter.includes(REGIMEN_STATUS_LIST[item.latestRegimenStatus])));

// eslint-disable-next-line max-len
export const filterByPhysician = (data, physicianId) => _.filter(data, (item) => item.physicianId === physicianId);

/** TO be removed as soon as we have another function exported;
 * placeholder to silent eslint errors.
 */
export default {};
