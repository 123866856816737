/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { KnSubtleText } from 'components/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KnButton from 'components/Button';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import KnEditIcon from 'components/icons/EditIcon';
import { KnActionLink } from 'components/Link';
import { styled, TextareaAutosize, Typography } from '@material-ui/core';
import {
  getCompletedDosages, capFirstCharOnly, tabletCountDisplay, calculateTabletCount,
} from 'utils/utils';

const KnDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'block',
  textAlign: 'center',
  padding: theme.spacing(6),
  paddingBottom: theme.spacing(3),
  paddingTop: theme.spacing(3),
  '& button:nth-child(2)': {
    marginLeft: 0,
    marginTop: theme.spacing(1),
  },
}));

const KnDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const KnTextCopyDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 600,
  },
});

const KnCopyTextArea = styled(TextareaAutosize)(({ theme }) => ({
  outline: 'none',
  border: 0,
  width: '100%',
  resize: 'none',
  maxHeight: 370,
  overflowY: 'scroll !important',
  padding: theme.spacing(2),
  color: palette.gunmetal,
}));

const KnCopySubtleText = styled(KnSubtleText)({
  fontSize: 14,
  textAlign: 'right',
  display: 'block',
});

/**
 * Medication Regiment to text
 */
const KnRegimenToText = ({ titration, regimens }) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [inputText, setInputText] = useState('');
  const { t: translate } = useTranslation();

  const {
    medications = [], startedAt, parentTitrationId, note,
  } = titration;

  const strengthValue = (d, t, medicationStrength, medicationType) => `(${tabletCountDisplay(calculateTabletCount(
    d.individualQuantities[t.index],
    medicationStrength,
  ), medicationType).replace(/ /g, '')})`;

  const getDuration = useCallback((duration) => {
    if (duration % 7 === 0) {
      return `${duration / 7}w;`;
    }
    return `${duration}d;`;
  }, []);

  const defaultText = useMemo(() => {
    let text = '';
    const parentTitration = regimens.find((r) => r.id === parentTitrationId);
    const { medics } = getCompletedDosages(medications, parentTitration, startedAt);
    medics.forEach((m) => {
      const {
        medicationStrength, medicationType, medicationTypeUnit,
      } = m;
      text += `Take ${m.activeIngredient}(${m.name}) from day ${m.offset ? m.offset + 1 : 1}:\n`;
      if (medicationStrength) text += `${capFirstCharOnly(medicationType)}${translate('FIELD_LABELS.suspensionStrength')}${medicationStrength}${medicationTypeUnit}\n`;
      m.dosages.forEach((d) => {
        d.times.forEach((t) => {
          const customDosageString = `${d.individualQuantities[t.index]}${m.unit}${medicationStrength ? strengthValue(d, t, medicationStrength, medicationType) : ' '}${t.timePeriod.value}`;
          if (t.index === 0 && d.times.length === t.index + 1) {
            text += `  - ${customDosageString} ${getDuration(d.duration, d.ongoing)}\n`;
          } else if (t.index === 0) {
            text += `  - ${customDosageString}, `;
          } else if (d.times.length === t.index + 1) {
            text += `${customDosageString} ${getDuration(d.duration, d.ongoing)}\n`;
          } else {
            text += `${customDosageString}, `;
          }
        });
      });
      text += '\n\n';
    });
    if (note) {
      text += `Note: ${note}`;
    }
    return text;
  }, [medications, startedAt, regimens, getDuration, parentTitrationId, note, translate]);

  useEffect(() => {
    setInputText(defaultText);
  }, [defaultText, setInputText, open]);


  const openTextDialog = () => {
    setOpen(true);
  };

  const onCloseCopyDialog = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setInputText(e.target.value);
  };

  const onCopyText = () => {
    navigator.clipboard.writeText(inputText).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  return (
    <>
      <KnActionLink
        onClick={openTextDialog}
        LhsIcon={KnEditIcon}
        data-testid="export-medication-to-text"
      >
        {translate('PATIENT_RECORD.medicationRegimen.exportRegimentToText')}
      </KnActionLink>
      <KnTextCopyDialog
        open={open}
        onClose={onCloseCopyDialog}
        data-testid="dialog-wrapper"
      >
        <KnDialogTitle data-testid="dialog-title">{translate('PATIENT_RECORD.medicationRegimen.regimenSummary')}</KnDialogTitle>
        <DialogContent data-testid="dialog-content">
          <KnCopyTextArea onChange={handleChange} rowsMin={15} value={inputText} />
          <Typography component={KnCopySubtleText}>
            {(inputText || '').trim().length}
            {' '}
            Characters
          </Typography>
        </DialogContent>
        <KnDialogActions data-testid="dialog-actions">
          <KnButton fullWidth onClick={onCopyText} data-testid="dialog-submit-button">
            {translate(`PATIENT_RECORD.medicationRegimen.${copied ? 'copied' : 'copyText'}`)}
          </KnButton>
          <KnButton fullWidth variant="text" onClick={onCloseCopyDialog} data-testid="dialog-cancel-button">
            {translate('ASSIGN_REGIMEN.returnToPatient')}
          </KnButton>
        </KnDialogActions>
      </KnTextCopyDialog>
    </>
  );
};

KnRegimenToText.defaultProps = {
};

KnRegimenToText.propTypes = {
  titration: PropTypes.shape().isRequired,
  regimens: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default KnRegimenToText;
