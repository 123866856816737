/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/
import { Droppable, Draggable } from 'react-beautiful-dnd';
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDialog from 'components/dialog/DialogService';
import { KnActionLink } from 'components/Link';
import KnPlusIcon from 'components/icons/PlusIcon';
import KnDeleteIcon from 'components/icons/DeleteIcon';
import KnStrengthDialog from 'components/titrations/form-components/StrengthDialog';
import {
  withKeyNamespace,
} from 'utils/utils';
import {
  Box, Typography,
}
  from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { KnSubtleText } from 'components/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import KnTitrationFormDosage from './TitrationFormDosage';
import {
  KnCustomDosageFormControlLabel,
} from '../styles';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

const KnCustomDosageFormControlLabelChange = styled(KnCustomDosageFormControlLabel)({
  marginLeft: '10px', position: 'relative', top: 0,
});

/**
 * Component used by the KnTitrationForm,
 * for editing one medication of the titration
 */
const KnTitrationFormMedication = (props) => {
  const { t: translate } = useTranslation();
  const dialog = useDialog();
  const {
    index: medIndex,
    context,
    medication,
    onAddDosage,
    onRemoveMediction,
    onMedicationChange,
    onMedicationDosageRemove,
    dataTestId,
    onChangeDosage,
    showDeleteMedication,
    ...rest
  } = props;
  const {
    dosages,
    id: medId,
    unit,
    medicationStrength,
    medicationType,
    medicationTypeUnit,
  } = medication;

  /**
   * Droppable and draggable must share the same type
   * to prevent other type draggables to be dropped.
   */
  const droppableType = `med-${medId}`;
  const onMedicationChangeHandler = useCallback((dosageId, updates) => {
    onMedicationChange(medId, dosageId, updates);
  }, [medId, onMedicationChange]);
  const onMedicationDosageRemoveHandler = useCallback((dosageId) => {
    onMedicationDosageRemove(medId, dosageId);
  }, [medId, onMedicationDosageRemove]);

  const onSetStrengthClick = useCallback(() => {
    dialog({
      customDialog: KnStrengthDialog,
      inputData: { medicationStrength, medicationType, medicationTypeUnit },
    }).then((updates) => {
      onMedicationChange(medId, null, updates);
      const medStrength = updates.medicationStrength;
      if (medStrength) onChangeDosage(medId);
    });
  }, [dialog, medId, medicationStrength,
    onMedicationChange, medicationType, medicationTypeUnit, onChangeDosage]);

  return (
    <Droppable droppableId={medId.toString()} type={droppableType} direction="vertical">
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} data-testid={dataTestId}>
          <Box pb={5}>
            <Box display="flex" ml="35px" mt="-30px" mb="25px">
              <KnCustomDosageFormControlLabelChange
                size="medium"
                control={(
                  <Checkbox
                    checked={!!medicationStrength}
                    style={{ transform: 'scale(1.3)', marginRight: '3px' }}
                    onChange={onSetStrengthClick}
                    inputProps={{ 'data-testid': `custom-dosage-checkbox-${medIndex + 1}` }}
                  />
                )}
                label={(
                  <Typography variant="body2" component={KnSubtleText}>
                    {translate('TITRATIONS.titrationBuilder.setStrengthDialog.checkBox')}
                    {medicationStrength ? ' - ' : '/'}
                    {medicationStrength
                      ? (<b>{`${medicationType.toUpperCase()} - ${medicationStrength} ${medicationTypeUnit}`}</b>)
                      : translate('TITRATIONS.titrationBuilder.setStrengthDialog.concentration')}
                  </Typography>
                )}
              />
            </Box>

            <hr style={{ marginTop: 25, marginBottom: 30 }} />
            {dosages.map((dosage, index) => (
              <Draggable
                key={dosage.keyId}
                draggableId={dosage.keyId}
                index={index}
                type={droppableType}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    data-testid={`dosage-wrapper-${index + 1}`}
                    {...draggableProvided.draggableProps}
                  >
                    <KnTitrationFormDosage
                      dosage={dosage}
                      unit={unit}
                      medicationStrength={medicationStrength}
                      medicationType={medicationType}
                      medicationStrengthSet={!!medicationStrength}
                      index={index}
                      onChange={onMedicationChangeHandler}
                      onRemove={onMedicationDosageRemoveHandler}
                      dragHandleProps={draggableProvided.dragHandleProps}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            <div>
              {!snapshot.isDraggingOver && !snapshot.draggingFromThisWith && (
                <>
                  <KnActionLink
                    onClick={() => onAddDosage(medId)}
                    LhsIcon={KnPlusIcon}
                    style={{ float: 'left' }}
                    data-testid="add-dosage-link"
                  >
                    {translate(i18nKey('addDosage'))}
                  </KnActionLink>
                  {showDeleteMedication && (
                    <KnActionLink
                      onClick={() => onRemoveMediction(medId)}
                      LhsIcon={KnDeleteIcon}
                      style={{ float: 'right' }}
                      data-testid="delete-medication-link"
                    >
                      {translate(i18nKey('deleteMedication'))}
                    </KnActionLink>
                  )}
                </>
              )}
            </div>
          </Box>
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
KnTitrationFormMedication.defaultProps = {
  onRemoveMediction: () => { },
  context: '',
};
KnTitrationFormMedication.propTypes = {
  index: PropTypes.number.isRequired,
  medication: PropTypes.shape({
    id: PropTypes.number.isRequired,
    activeIngredient: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    medicationStrength: PropTypes.number,
    medicationType: PropTypes.string,
    medicationTypeUnit: PropTypes.string,
    dosages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  onAddDosage: PropTypes.func.isRequired,
  onChangeDosage: PropTypes.func.isRequired,
  onMedicationChange: PropTypes.func.isRequired,
  onMedicationDosageRemove: PropTypes.func.isRequired,
  onRemoveMediction: PropTypes.func,
  dataTestId: PropTypes.string.isRequired,
  showDeleteMedication: PropTypes.bool.isRequired,
  context: PropTypes.string,
};
export default KnTitrationFormMedication;
