/**
* @copyright Copyright (C) 2021 Nile AI, Inc - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { Auth } from 'aws-amplify';
import ACTION_TYPES from 'redux/actionTypes';
import {
  API_URLS,
  API_REQUEST_METHODS,
  API_REQUEST_ERROR_CODES,
  ERROR_CODES,
} from 'Constants';
import { setInsightsContext } from 'appInsights';
import apiRequest from './apiActions';
import appActions from './appActions';
import loginActions from './loginActions';
import { mapUserRoleToLocal } from './userRoleActions';

const mapHCPUserToLocal = (data) => ({
  userId: data.id,
  accountNumber: data.accountNumber,
  email: data.email,
  phoneNumber: data.phoneNumber,
  firstName: data.firstName,
  lastName: data.lastName,
  userPreferences: data.userPreferences,
  role: mapUserRoleToLocal(data.hcpRole),
  healthSystemId: _.get(data, 'healthSystem.id'),
  healthSystemName: _.get(data, 'healthSystem.name'),
  status: _.get(data, 'status'),
});

const mapHCPUsersToLocal = (data) => data.map((user) => mapHCPUserToLocal(user));

/**
 * Update Disclaimer .
 *
 * @param {object} data Object which contains only the actual modified fields.
 */
const userPrefernce = (preference) => {
  const success = (data) => ({ type: ACTION_TYPES.CURRENT_USER_PREFERENCE, data });
  const failure = () => ({ type: ACTION_TYPES.CURRENT_USER_ERROR });

  return async (dispatch, getState) => {
    const { currentUser } = getState().user;
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PATCH,
          API_URLS.preference,
          preference,
        ),
      );
      dispatch(success({ ...currentUser }));
    } catch (errorCode) {
      dispatch(failure());
      dispatch(appActions.appPushNotification('USER.ERROR_MESSAGES.currentUserError'));
    }
  };
};

/**
 * Action creator to fetch the current user data.
 * If the request fails with 404 error code, then
 * the user will be logged out.
 */
const getCurrentUser = (callIt) => {
  const success = (data) => ({ type: ACTION_TYPES.CURRENT_USER_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.CURRENT_USER_ERROR });

  return async (dispatch, getState) => {
    const { currentUser } = getState().user;
    try {
      /**
       * Current user data is fetched only on the first request
       * OR on page reload due to Redux store re-creation.
       */
      if (!currentUser.initialized || callIt) {
        const response = await dispatch(
          apiRequest(
            API_REQUEST_METHODS.GET,
            API_URLS.users,
          ),
        );
        const hcpUserData = mapHCPUserToLocal(response.data);
        setInsightsContext(hcpUserData.accountNumber, hcpUserData.healthSystemName);
        dispatch(success(hcpUserData));
      }
    } catch (errorCode) {
      if (errorCode === API_REQUEST_ERROR_CODES.USER_NOT_FOUND) {
        dispatch(loginActions.logout());
      } else {
        dispatch(failure());
        dispatch(appActions.appPushNotification('USER.ERROR_MESSAGES.currentUserError'));
      }
    }
  };
};

/**
 * Action creator to fetch the list of HCP users with physician role
 */
const getPhysicians = (forceRequest = false) => {
  const success = (data) => ({ type: ACTION_TYPES.PHYSICIANS_LIST_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PHYSICIANS_LIST_ERROR, data: [] });

  return async (dispatch, getState) => {
    const { physiciansList } = getState().user;
    try {
      /**
       * Physicians list is fetched only:
       * 1. until the first successful request
       * 2. on page reload due to Redux store re-creation
       * 3. when forceRequest is set to true
       */
      if (forceRequest || !physiciansList.initialized) {
        const response = await dispatch(
          apiRequest(
            API_REQUEST_METHODS.GET,
            API_URLS.physicians,
          ),
        );
        dispatch(success(mapHCPUsersToLocal(response.data)));
      }
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Update account profile action creator.
 *
 * @param {object} data Object which contains only the actual modified fields.
 */
const updateProfile = (updates) => {
  const success = (data) => ({ type: ACTION_TYPES.UPDATE_PROFILE_SUCCESS, data });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PATCH,
          API_URLS.users,
          updates,
        ),
      );

      dispatch(success(updates));
      /** Current doctor profile changed, let's refresh our doctors list */
      dispatch(getPhysicians(true));
      return Promise.resolve();
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PROFILE.ERROR_MESSAGES.updateProfileError'));
      return Promise.reject(errorCode);
    }
  };
};

/**
 * Performs password change request
 * @param user Currently signed in Cognito user object
 * @param oldPassword Old password
 * @param newPassword New password
 */
const changePassword = (user, oldPassword, newPassword) => (dispatch) => {
  const failure = (errorCode) => {
    dispatch(appActions.appPushNotification(`CHANGE_PASSWORD.ERROR_MESSAGES.${errorCode}`));
    return Promise.reject(errorCode);
  };

  dispatch(appActions.appPushBusy());
  dispatch(appActions.appPopNotification());

  /** Call Cognito change password submit */
  return Auth.changePassword(user, oldPassword, newPassword)
    .then(
      () => ({ success: true }),
      (error) => {
        switch (error.code) {
          /** Handling different error scenarios */
          case ERROR_CODES.NOT_AUTHORIZED:
          case ERROR_CODES.LIMIT_EXCEEDED:
          case ERROR_CODES.INVALID_PARAMETER:
            /** We translate invalid parameter error into 'invalid password' messaage
             * (see the translation file),
             * as that can be the only issue, as newPassword got validated locally beforehand
            */
            return failure(error.code);
          default:
            /** Show same generic error, regardless of the cause */
            return failure(ERROR_CODES.CHANGE_PASSWORD_ERROR);
        }
      },
    )
    .finally(() => dispatch(appActions.appPopBusy()));
};

export default {
  getCurrentUser,
  userPrefernce,
  getPhysicians,
  updateProfile,
  changePassword,
};
